@import 'variables';
@import '~src/assets/scss/functions';

/*
 * The custom classes prefixed with 'notif-' should be used here where possible so they
 * keep functioning if the Swal team ever decide to change their class names
 */

.notif-container {
  &.swal2-shown {
    animation: fadeIn 0.3s;
  }

  .notif-popup {
    border-radius: 5px;
    padding: 1.25rem;

    .notif-header .notif-title {
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .notif-content {
      font-size: 14px;
      color: $color-text-dark;

      &.branded {
        margin: -1.25rem -1.25rem 0;
        padding: 1.25rem;
        color: $color-container-background;
        background-color: $color-brand;
        font-size: 16px;
      }

      .withdraw-notif-content {
        border-top: $color-brand 4px solid;
        display: flex;
        align-items: center;
        margin: -15px;
        padding: 15px;

        .icon {
          font-size: 30px;
          padding: 15px;
          color: $color-brand;
        }

        .text {
          font-weight: bold;
          font-size: 13px;
          text-align: justify;
        }
      }
    }

    .notif-actions {
      margin: 1em auto 0;
      justify-content: space-between;

      button {
        min-width: 108px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        color: $color-text-light;
        border: none;
        border-radius: 4px;
        outline: none;
        padding: 0 12px;

        &.notif-cancel-button {
          background-color: $color-danger;
        }

        &.notif-confirm-button {
          background-color: $color-brand;
        }
      }

      &.single-button button {
        width: 100%;
      }
    }

    .notif-footer {
      margin: 1em 0 0;
    }

    &.type-success {
      background-color: lighten($color-brand, 54%);

      .notif-header .notif-title {
        color: $color-brand;
      }
    }

    &.type-warning {
      background-color: lighten($color-warning, 40%);

      .notif-header .notif-title {
        color: $color-text-dark;
      }
    }

    &.type-error {
      background-color: lighten($color-danger, 34%);

      .notif-header .notif-title {
        color: $color-danger;
      }
    }

    &.swal2-show {
      animation: zoomIn 0.3s;
    }
  }

  .notif-toast {
    border-radius: 0;
    box-shadow: 0 0 6px #333 !important;

    .notif-content {
      font-size: 16px;

      .toast-content {
        display: flex;
        align-items: center;

        .toast-icon {
          font-size: 20px;
          margin-right: 10px;
        }

        .toast-text {
          width: 100%;
          text-align: center;
        }
      }
    }

    &.type-success {
      background-color: $color-brand;

      .notif-content {
        color: set-text-color($color-brand);
      }
    }

    &.type-warning {
      background-color: lighten($color-warning, 40%);

      .notif-content {
        color: $color-text-dark;

        .toast-content .toast-icon {
          color: $color-warning;
        }
      }
    }

    &.type-error {
      background-color: lighten($color-danger, 34%);

      .notif-content {
        color: $color-danger;
      }
    }

    &.swal2-show {
      animation: slideInUp 0.5s;
    }

    &.swal2-hide {
      animation: slideOutUp 0.5s;
    }
  }

  &.dialog-container {
    padding: 0 40px !important;
  }
}

/*
 * The 'uj-notif-*' classes are used only in the account -> payments -> user-payment-journey module but the
 * styles need to be declared globally due to the way Sweetalert2 works
 */

.uj-notif-container {
  .uj-notif-popup {
    border-radius: 8px;
    padding: 0;

    .uj-notif-title {
      width: 100%;
      padding: 24px 24px 0;
      margin: 0;
      text-align: left;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #001041;
    }

    .uj-notif-content {
      width: 100%;
      padding: 24px;
      text-align: justify;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: rgba($color-text-dark, 0.6);

      p {
        margin: 0;
      }

      .uj-notif-actions {
        // this is only used when the actions component is overriden through the use of *swalPortal="swalTargets.content"
        // and the uj-notif-actions are needed inside of it (mainly due to forms)
        margin: 24px -24px -24px;
        width: calc(100% + 48px);
      }
    }

    .uj-notif-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-top: solid 1px rgba(0, 0, 0, 0.12);
      padding: 10px;
      margin: 0;

      button {
        background: transparent;
        border: none;
        padding: 10px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 1.25px;
        color: $color-brand;
        outline: none;

        &:nth-child(n + 2) {
          margin-left: 10px;
        }

        &:disabled {
          color: rgba($color-text-dark, 0.38);
        }
      }
    }
  }
}

/*
 * The 'vir-notif-*' classes are used only in the account -> payments -> user-payment-journey module but the
 * styles need to be declared globally due to the way Sweetalert2 works
 */

.virtuals-notif-container {
  font-family: 'Roboto Condensed', sans-serif;

  .virtuals-notif-popup {
    border-radius: 8px;
    padding: 16px;
  }
}

 /**
 * Styles for the 'redesign' module of notifications.
 * The styles are declared globally due to the architectural requirements of the Sweetalert2 library.
 */
 .notif-container.module-redesign, {
  .type-info {
    .swal2-header {
      align-items: flex-start; // Left align

      .notif-title {
        padding: 9px 9px 12px;
        font-size: 20px;
        font-weight: normal;
        text-transform: unset;
        color: $color-brand-dark-with-opacity;
      }
    }

    .swal2-content {
      padding: 1.5em .7em .9em;
      text-align: left;
      color: $color-brand-title-secondary;
    }

    .notif-actions {
      justify-content: flex-end;

      .swal2-confirm {
        width: 100%;
        height: 42px;
        margin: 0 0 24px;
        border-radius: 50px;
        color: $color-brand-darker;
        background: $color-brand-accent-secondary;
      }

      .swal2-cancel {
        padding: 0;
        min-width: auto;
        margin: 12px 0 0;
        color: $color-brand;
        background-color: transparent;
      }
    }
  }
}



// Animations copied from the animate.css plugin
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes slideInUp {
  from {
    bottom: -100%;
    visibility: visible;
  }

  to {
    bottom: 0;
  }
}

@keyframes slideOutUp {
  from {
    bottom: 0;
  }

  to {
    visibility: hidden;
    bottom: 100%;
  }
}
