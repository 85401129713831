.odds-boost-badge {
  background-image: url('/assets/images/odds-boost/odds-boost-icon.svg');
  background-color: $color-brand-accent;
  background-position: center;
  background-size: 12px 12px;
  border-radius: 14px;
  border: 2px solid white;
  display: block;
  height: 20px;
  overflow: visible;
  width: 20px;
}

.badge {
  background-color: $color-notifications;
  color: $color-text-light;
  font-family: $font-family;
  font-weight: bold;
  padding-top: 2px;
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  z-index: 1;
  line-height: 0;
}

.badge-inverse {
  background-color: $color-container-background;
  color: $color-brand;
  font-family: $font-family;
  font-weight: bold;
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  z-index: 1;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.3);
}

.badge-red {
  background-color: $color-notifications;
  color: $color-text-light;
  font-family: $font-family;
  text-align: center;
  border-radius: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  right: 4px;
  z-index: 1;
  font-weight: bold;
  padding: 0 2px;
}
